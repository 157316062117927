class CartItemImageInfo {
    public productId: number;
    public image: string;
    public containerName: string;
    public defaultImage: number;

    constructor(imageData: any = {}) {
        this.productId = imageData.productId;
        this.image = imageData.image;
        this.containerName = imageData.containerName;
        this.defaultImage = imageData.defaultImage;
    }
}

export class CartItem {
    public productId: number;
    public sku: string;
    public upc: string;
    public location: string;
    public minimumQuantity: number;
    public subtractStock: number;
    public stockStatusId: number;
    public manufacturerId: number;
    public shipping: number;
    public todayDeals: number;
    public price: number;
    public dateAvailable: string;
    public sortOrder: number;
    public name: string;
    public description: string;
    public metaTagTitle: string;
    public isFeatured: number;
    public isActive: number;
    public image: CartItemImageInfo;
    public productCount: number;

    constructor(product: any = {}) {
        let productInfo = product.product ? product.product : product;
        let productCount = product.product ? product.quantity : product.productCount;
        
        this.productId = product.productId;
        this.sku = productInfo.sku;
        this.upc = productInfo.upc;
        this.location = productInfo.location;
        this.minimumQuantity = productInfo.minimumQuantity;
        this.subtractStock = productInfo.subtractStock;
        this.stockStatusId = productInfo.stockStatusId;
        this.manufacturerId = productInfo.manufacturerId;
        this.shipping = productInfo.shipping;
        this.todayDeals = productInfo.todayDeals;
        this.price = parseFloat(productInfo.price) || 0;
        this.dateAvailable = productInfo.dateAvailable;
        this.sortOrder = productInfo.sortOrder;
        this.name = productInfo.name;
        this.description = productInfo.description;
        this.metaTagTitle = productInfo.metaTagTitle;
        this.isFeatured = productInfo.isFeatured;
        this.isActive = productInfo.isActive;
        this.image = new CartItemImageInfo(productInfo.image);
        this.productCount = productCount;
    }
}