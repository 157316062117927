import { createSelector } from '@ngrx/store';
import { ICartState } from './cart.state';
import { AppState } from '../../state.interface';

export const selectFeature = (state: AppState) => state.cart;
export const cartItemsSelector = createSelector(
    selectFeature,
    (cart: ICartState) => cart.items ? cart.items : []
);

export const cartItemsCountSelector = createSelector(
    selectFeature,
    (cart: ICartState) => {
        let items = cart.items ? cart.items : [];
        let itemCount = 0;
        items.forEach((item) => {
            itemCount += item.productCount;
        });
        return itemCount || 0;
    }
);

export const cartItemByIdSelector = createSelector(
    selectFeature,
    (cart: ICartState, props: { productId: number }) => {
        let items = cart.items ? cart.items : [];
        return items.findIndex(item => item.productId === props.productId);
    }
);

export const cartItemCountByIdSelector = createSelector(
    selectFeature,
    (cart: ICartState, props: { productId: number }) => {
        let foundProduct = cart.items.find((item) => item.productId === props.productId);
        if (foundProduct) {
            return foundProduct.productCount;
        }
    }
);

export const cartTotalPriceSelector = createSelector(
    selectFeature,
    (cart: ICartState) => {
        let price = 0;
        cart.items.forEach((item) => {
            price += item.productCount * (item.price || 0);
        });
        return price;
    }
);

export const buyProductPendingSelector = createSelector(
    selectFeature,
    (cart: ICartState) => cart.productBuyPending
);