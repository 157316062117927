export interface IUserInResponse {
    firstName: string;
    email: string;
    mobileNumber: string;
    avatar: any;
    avatarPath: any;
}

export class User {
    public firstName: string;
    public email: string;
    public phone: string;
    public avatar: any;
    public avatarPath: any;

    constructor(data: IUserInResponse) {
        this.firstName = data.firstName || '';
        this.email = data.email || '';
        this.phone = data.mobileNumber || null;
        this.avatar = data.avatar || null;
        this.avatarPath = data.avatarPath || null;
    }
}