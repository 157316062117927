import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

export enum SnackBarClass {
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

@Injectable()
export class ToastService {
    private readonly snackBarAction = '×';
    private readonly snackBarDuration = 4500;
    private readonly snackBarConfig: MatSnackBarConfig = {
        verticalPosition: 'top',
        horizontalPosition: 'right',
    }

    constructor(private snackBar: MatSnackBar) {}

    public success(
        message: string,
        action: string = this.snackBarAction,
        duration: number = this.snackBarDuration
    ): void {
        this.snackBar.open(message, action, {
            ...this.snackBarConfig,
            panelClass: 'success',
            duration
        });
    }

    public error(
        message: string,
        action: string = this.snackBarAction,
        duration: number = this.snackBarDuration
    ): void {
        this.snackBar.open(message, action, {
            ...this.snackBarConfig,
            panelClass: 'error',
            duration
        });
    }
}