import { ICartState, CartRecord } from './cart.state';
import * as actions from '../actions/cart.actions';
import { CartItem } from '../models/cart.models';

export const initialState: ICartState = new CartRecord().toJS() as ICartState;

export function cartReducer(
    state = initialState,
    { type, payload }: any
): ICartState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.CART_ITEMS_LOAD_PENDING: {
            return {
                ...state,
                cartItemsLoadPending: true,
            }
        }

        case actions.ActionTypes.CART_ITEMS_LOAD_SUCCESS: {
            const items = payload.map(product => (new CartItem(product)));
            return {
                ...state,
                items: items,
                cartItemsLoadPending: false,
            }
        }

        case actions.ActionTypes.CART_ITEMS_LOAD_FAILURE: {
            return {
                ...state,
                cartItemsLoadPending: false,
            }
        }

        case actions.ActionTypes.CART_ITEM_ADDITION_PENDING: {
            return {
                ...state,
                cartItemAdditionPending: true
            };
        }

        case actions.ActionTypes.CART_ITEM_ADDITION_SUCCESS: {
            const item = new CartItem(payload);
            const existingItems = state.items.slice();
            existingItems.push(item);
            return {
                ...state,
                items: existingItems,
                cartItemAdditionPending: false
            };
        }

        case actions.ActionTypes.CART_ITEM_ADDITION_FAILURE: {
            return {
                ...state,
                cartItemAdditionPending: false
            };
        }

        case actions.ActionTypes.PRODUCT_BUY_PENDING: {
            return {
                ...state,
                productBuyPending: true
            }
        }

        case actions.ActionTypes.PRODUCT_BUY_SUCCESS: {
            let updatedState = {};
            let productIndex = state.items.findIndex((product) => product.productId === payload.productId);
            let existingItems = state.items.slice();
            updatedState = Object.assign(updatedState, {
                productBuyPending: false
            });
            if (productIndex === -1) {
                updatedState = Object.assign(updatedState, {
                    items: [...existingItems, new CartItem(payload)]
                });
            }
            return {
                ...state,
                ...updatedState,
            }
        }

        case actions.ActionTypes.PRODUCT_BUY_FAILURE: {
            return {
                ...state,
                productBuyPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVAL_PENDING: {
            return {
                ...state,
                cartItemRemovalPending: true
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVAL_SUCCESS: {
            let filteredItems = state.items.slice().filter((item) => {
                return item.productId !== payload.productId;
            });
            return {
                ...state,
                items: filteredItems,
                cartItemRemovalPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVAL_FAILURE: {
            return {
                ...state,
                cartItemRemovalPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVE_ALL_PENDING: {
            return {
                ...state,
                cartItemRemoveAllPending: true
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVE_ALL_SUCCESS: {
            return {
                ...state,
                items: [],
                cartItemRemoveAllPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_REMOVE_ALL_FAILURE: {
            return {
                ...state,
                cartItemRemoveAllPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_INCREMENT_PENDING: {
            return {
                ...state,
                cartItemCountIncrementPending: true
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_INCREMENT_SUCCESS: {
            let { increaseCountBy, productId } = payload as actions.IProductCountIncrementArg;
            let items = state.items.slice();
            let foundItem = items.find((item) => item.productId === productId);
            if (foundItem) {
                foundItem.productCount += increaseCountBy;
            }
            return {
                ...state,
                cartItemCountIncrementPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_INCREMENT_FAILURE: {
            return {
                ...state,
                cartItemCountIncrementPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_DECREMENT_PENDING: {
            return {
                ...state,
                cartItemCountDecrementPending: true
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_DECREMENT_SUCCESS: {
            let { decreaseCountBy, productId } = payload as actions.IProductCountDecrementArg;
            let items = state.items.slice();
            let foundItem = items.find((item) => item.productId === productId);
            if (foundItem) {
                foundItem.productCount -= decreaseCountBy;
            }
            return {
                ...state,
                cartItemCountDecrementPending: false
            }
        }

        case actions.ActionTypes.CART_ITEM_COUNT_DECREMENT_FAILURE: {
            return {
                ...state,
                cartItemCountDecrementPending: false
            }
        }
            
        case actions.ActionTypes.CART_CLEAR: {
            return {
                ...state,
                ...new CartRecord().toJS() as ICartState
            };
        }

        default: {
            return state;
        }
    }
}