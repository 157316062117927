export class productReviewListModel {
    public productReviewId: number;
    public productId: number;
    public customerId: number;
    public review: string;
    public rating: number;
    public customer: any;
    public reviewImage: any;

    constructor(productReviewData: any) {
        this.productReviewId = productReviewData.productReviewId;
        this.productId = productReviewData.productId;
        this.customerId = productReviewData.customerId;
        this.review = productReviewData.review;
        this.rating = productReviewData.rating;
        this.customer = productReviewData.customer;
        this.reviewImage = productReviewData.reviewImage;
    }
}