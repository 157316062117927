import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastService } from '../toast.service';

@Injectable()
export class ProtectedPagesGuard implements CanActivate {
    constructor(
        public router: Router,
        public toastService: ToastService,
    ) { }

    canActivate(): boolean {
        if (this.isLoggedIn()) {
            return true;
        }
        this.toastService.error('Login or Create Account to continue');
        this.router.navigate(['/auth']);
        return false;
    }

    private isLoggedIn(): boolean {
        return !!localStorage.getItem('userToken');
    }
}