import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state.interface';
import * as actions from '../cart/actions/cart.actions';
import * as cartStateSelectors from './reducers/cart.selectors';

@Injectable()
export class CartSandbox {
    public cartItems$ = this.appState$.select(cartStateSelectors.cartItemsSelector);
    public cartItemsCount$ = this.appState$.select(cartStateSelectors.cartItemsCountSelector);
    public cartTotalPrice$ = this.appState$.select(cartStateSelectors.cartTotalPriceSelector);
    public getItemCount = (productId: number) => {
        return this.appState$.select(cartStateSelectors.cartItemCountByIdSelector, { productId });
    };
    public doesItemExistsInCart = (productId: number) => {
        return this.appState$.select(cartStateSelectors.cartItemByIdSelector, { productId });
    };
    public buyProductPending$ = this.appState$.select(cartStateSelectors.buyProductPendingSelector);

    constructor(protected appState$: Store<AppState>) {
        this.loadItems();
    }

    public loadItems(): void {
        this.appState$.dispatch(new actions.CartItemsLoadPending());
        this.appState$.dispatch(new actions.CartItemsLoad());
    }

    public addProduct(actionPayload: actions.IAddToCartPayload): void {
        this.appState$.dispatch(new actions.CartItemAdditionPending());
        this.appState$.dispatch(new actions.CartItemAddition(actionPayload));
    }

    public removeProduct(product: any) {
        this.appState$.dispatch(new actions.CartItemRemovalPending());
        this.appState$.dispatch(new actions.CartItemRemoval(product));
    }

    public removeAllProducts() {
        this.appState$.dispatch(new actions.CartItemRemoveAllPending());
        this.appState$.dispatch(new actions.CartItemRemoveAll());
    }

    public increaseProductCount(changeDetails: actions.IProductCountIncrementArg) {
        this.appState$.dispatch(new actions.CartItemCountIncrementPending());
        this.appState$.dispatch(new actions.CartItemCountIncrement(changeDetails));
    }

    public decreaseProductCount(changeDetails: actions.IProductCountDecrementArg) {
        this.appState$.dispatch(new actions.CartItemCountDecrementPending());
        this.appState$.dispatch(new actions.CartItemCountDecrement(changeDetails));
    }

    public buyProductNow(actionPayload: actions.IAddToCartPayload): void {
        this.appState$.dispatch(new actions.ProductBuy(actionPayload));
        this.appState$.dispatch(new actions.ProductBuyPending());
    }
}