import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthPagesGuard implements CanActivate {
    constructor(public router: Router) { }
    
    canActivate(): boolean {
        if (this.isLoggedIn()) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

    private isLoggedIn(): boolean {
        return !!localStorage.getItem('userToken');
    }
}