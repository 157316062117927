import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { IProductCountDecrementArg, IProductCountIncrementArg } from './actions/cart.actions';
import { CartItem } from './models/cart.models';
import { Api } from '../providers/api/api';

@Injectable()
export class CartService extends Api {
    public addItem(payload: { productId: number; quantity?: number }): Observable<any> {
        return this.http.post(this.getApiUrl("cart/create-cart"), { ...payload });
    }

    public addItemToLocal(product: any): Observable<string> {
        let cart = this.getCartItemFromLocal();
        if (
            cart.length > 0 &&
            this.doesCartItemExistInLocal(product.productId)
        ) {
            return throwError(new Error('Item already exist in cart'));
        }
        cart.push(product);
        this.setCartItemToLocal(cart);
        return of('Successfully added');
    }

    public removeItem(productId: number): Observable<any> {
        return this.http.delete(this.getApiUrl("cart/delete-cart-item/" + productId));
    }

    public removeItemFromLocal(productId: number) {
        if (!this.doesCartItemExistInLocal(productId)) {
            throwError(new Error('Item not found'));
        }
        let cart = this.getCartItemFromLocal();
        let cartFiltered = cart.filter(cartItem => cartItem.productId !== productId);
        this.setCartItemToLocal(cartFiltered);
        return of('Item removed successfully');
    }

    public removeAllItems(): Observable<any> {
        return this.http.delete(this.getApiUrl("cart/delete-cart"));
    }

    public removeAllItemsFromLocal() {
        this.setCartItemToLocal([]);
        return of('All items removed successfully');
    }

    public loadItems(): Observable<any> {
        return this.http.get(this.getApiUrl("cart/cart-item-list"));
    }

    public loadItemsFromLocal() {
        return of(this.getCartItemFromLocal());
    }

    public incrementCartItemCount(args: IProductCountIncrementArg) {
        return this.http.put(this.getApiUrl("cart/quantity-increase/" + args.productId), {
            increment: args.increaseCountBy,
        });
    }

    public incrementCartItemCountInLocal(args: IProductCountIncrementArg) {
        const { increaseCountBy, productId } = args;
        const cart = this.getCartItemFromLocal();
        if (cart.length === 0) {
            return throwError(new Error('Cart is empty'));
        }
        if (!this.doesCartItemExistInLocal(productId)) {
            return throwError(new Error('Item is not in cart'));
        }
        let targetItem = cart.find(cartItem => cartItem.productId === productId);
        targetItem.productCount = (targetItem.productCount + increaseCountBy);
        this.setCartItemToLocal(cart);
        return of('Increased');
    }

    public decrementCartItemCount(args: IProductCountDecrementArg) {
        return this.http.put(this.getApiUrl("cart/quantity-decrease/" + args.productId), {
            increment: args.decreaseCountBy,
        });
    }

    public decrementCartItemCountInLocal(args: IProductCountDecrementArg) {
        const { decreaseCountBy, productId } = args;
        const cart = this.getCartItemFromLocal();
        if (cart.length === 0) {
            return throwError(new Error('Cart is empty'));
        }
        if (!this.doesCartItemExistInLocal(productId)) {
            return throwError(new Error('Item is not in cart'));
        }
        let targetItem = cart.find(cartItem => cartItem.productId === productId);
        if (targetItem.productCount === 1) {
            return throwError(new Error('Item count can not be less than 1'));
        }
        targetItem.productCount = (targetItem.productCount - decreaseCountBy);
        this.setCartItemToLocal(cart);
        return of('Decreased');
    }

    private doesCartItemExistInLocal(cartItemId: any): boolean {
        let cart = this.getCartItemFromLocal();
        let itemIndex = cart.findIndex(cartItem => cartItem.productId === cartItemId);
        if (itemIndex === -1) return false;
        return true;
    }

    private setCartItemToLocal(cart: any[]): void {
        localStorage.setItem('cart', JSON.stringify(cart));
    }

    private getCartItemFromLocal(): any[] {
        let cartStr = localStorage.getItem('cart');
        if (
            !cartStr || 
            !Array.isArray(JSON.parse(cartStr))
        ) return [];
        return JSON.parse(cartStr);
    }
}