import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, concatMap, tap } from 'rxjs/operators';
import * as actions from '../actions/cart.actions';
import { CartItem } from '../models/cart.models';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { ActionTypes as authActionTypes } from '../../auth/action/auth.action';
import { ToastService } from '../../service/toast.service';

@Injectable()
export class CartEffects {
    constructor(
        private actions$: Actions,
        private cartService: CartService,
        private router: Router,
        private toastService: ToastService
    ) {}

    private isUserLoggedIn(): boolean {
        return !!localStorage.getItem('userToken');
    }

    @Effect()
    addItem$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEM_ADDITION),
        map((action: actions.CartItemAddition) => action.payload),
        exhaustMap((payload: actions.IAddToCartPayload) => {
            let targetProduct = Object.assign({}, payload.product, { productCount: payload.itemCount });
            let reqPayload = {
                productId: payload.product.productId,
                quantity: payload.itemCount,
            };
            if (!this.isUserLoggedIn()) {
                return this.cartService.addItemToLocal(targetProduct).pipe(
                    map(() => {
                        this.toastService.success('Item added to cart successfully');
                        return new actions.CartItemAdditionSuccess(targetProduct);
                    }),
                    catchError(() => of(new actions.CartItemAdditionFailure()))
                )
            }
            return this.cartService.addItem(reqPayload).pipe(
                map(() => new actions.CartItemAdditionSuccess(targetProduct)),
                catchError(() => of(new actions.CartItemAdditionFailure()))
            );
        })
    );

    @Effect()
    removeItem$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEM_REMOVAL),
        map((action: actions.CartItemRemoval) => action.payload),
        exhaustMap((product: CartItem) => {
            let targetProduct = product;
            if (!this.isUserLoggedIn()) {
                return this.cartService.removeItemFromLocal(product.productId).pipe(
                    map(() => {
                        this.toastService.success('Successfully deleted cart item');
                        return new actions.CartItemRemovalSuccess(targetProduct);
                    }),
                    catchError(() => of(new actions.CartItemRemovalFailure()))
                );
            }
            return this.cartService.removeItem(product.productId).pipe(
                map(() => new actions.CartItemRemovalSuccess(targetProduct)),
                catchError(() => of(new actions.CartItemRemovalFailure()))
            );
        })
    );

    @Effect()
    removeAllItem$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEM_REMOVE_ALL),
        exhaustMap(() => {
            if (!this.isUserLoggedIn()) {
                return this.cartService.removeAllItemsFromLocal().pipe(
                    map(() => {
                        this.toastService.success('Successfully cleared cart');
                        return new actions.CartItemRemoveAllSuccess()
                    }),
                )
            }
            return this.cartService.removeAllItems().pipe(
                map(() => new actions.CartItemRemoveAllSuccess()),
                catchError(() => of(new actions.CartItemRemoveAllFailure()))
            )
        })
    );

    @Effect()
    increaseItemCount$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEM_COUNT_INCREMENT),
        map((action: actions.CartItemCountIncrement) => action.payload),
        exhaustMap((payload: any) => {
            let payloadLocal = payload;
            if (!this.isUserLoggedIn()) {
                return this.cartService.incrementCartItemCountInLocal(payload).pipe(
                    map(() => new actions.CartItemCountIncrementSuccess(payloadLocal)),
                    catchError(() => of(new actions.CartItemCountIncrementFailure()))
                );
            }
            return this.cartService.incrementCartItemCount(payload).pipe(
                map(() => new actions.CartItemCountIncrementSuccess(payloadLocal)),
                catchError(() => of(new actions.CartItemCountIncrementFailure()))
            );
        })
    );

    @Effect()
    decreaseItemCount$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEM_COUNT_DECREMENT),
        map((action: actions.CartItemCountDecrement) => action.payload),
        exhaustMap((payload: any) => {
            let payloadLocal = payload;
            if (!this.isUserLoggedIn()) {
                return this.cartService.decrementCartItemCountInLocal(payload).pipe(
                    map(() => new actions.CartItemCountDecrementSuccess(payloadLocal)),
                    catchError(() => of(new actions.CartItemCountDecrementFailure()))
                ); 
            }
            return this.cartService.decrementCartItemCount(payload).pipe(
                map(() => new actions.CartItemCountDecrementSuccess(payloadLocal)),
                catchError(() => of(new actions.CartItemCountDecrementFailure()))
            );
        })
    );

    @Effect()
    loadItems$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.CART_ITEMS_LOAD),
        exhaustMap(() => {
            if (!this.isUserLoggedIn()) {
                return this.cartService.loadItemsFromLocal().pipe(
                    map((cartItems: any[]) => new actions.CartItemsLoadSuccess(cartItems)),
                )
            }
            return this.cartService.loadItems().pipe(
                map((response: any) => new actions.CartItemsLoadSuccess(response.data)),
                catchError(() => of(new actions.CartItemsLoadFailure()))
            )
        })
    );

    @Effect()
    buyProductNow$: Observable<Action> = this.actions$.pipe(
        ofType(actions.ActionTypes.PRODUCT_BUY),
        map((action: actions.ProductBuy) => action.payload),
        exhaustMap((payload: actions.IAddToCartPayload) => {
            let targetProduct = Object.assign({}, payload.product, { productCount: payload.itemCount });
            let reqPayload = {
                productId: payload.product.productId,
                quantity: payload.itemCount,
            };
            return this.cartService.addItem(reqPayload).pipe(
                map(() => {
                    this.router.navigate(['/checkout']);
                    return new actions.ProductBuySuccess(targetProduct);
                }),
                catchError(() => of(new actions.ProductBuyFailure()))
            );
        })
    );

    @Effect()
    loginSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(
            authActionTypes.DO_LOGIN_SUCCESS,
            authActionTypes.LOGIN_CHECK_SUCCESS
        ),
        tap(() => localStorage.removeItem('cart')),
        concatMap(() => of(
            new actions.CartItemsLoadPending(),
            new actions.CartItemsLoad()
        ))
    );

    @Effect()
    logOutSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.LOGOUT_COMPLETE),
        map(() => new actions.CartClear())
    );
}