import { Map, Record } from 'immutable';
import { CartItem } from '../models/cart.models';

export interface ICartState extends Map<string, any> {
    items: Array<CartItem>;
    cartItemAdditionPending: boolean;
    cartItemRemovalPending: boolean;
    cartItemRemoveAllPending: boolean;
    cartItemsLoadPending: boolean;
    cartItemCountIncrementPending: boolean;
    cartItemCountDecrementPending: boolean;
    productBuyPending: boolean;
}

export const CartRecord = Record({
    items: [],
    cartItemAdditionPending: false,
    cartItemRemovalPending: false,
    cartItemRemoveAllPending: false,
    cartItemsLoadPending: false,
    cartItemCountIncrementPending: false,
    cartItemCountDecrementPending: false,
    productBuyPending: false
});
