export class RelatedProductList {
    // public name: string;
    // public price: number;
    // public metaTagTitle: string;
    // public isActive: number;
    // public sku: string;
    // public upc: string;
    // public quantity: number;
    public productDetails: any;
    public productId: number;
    public productImage: any;

    constructor(relatedProductListResponse: any) {
        // this.name = relatedProductListResponse.name || '';
        // this.price = relatedProductListResponse.price || 0;
        // this.metaTagTitle = relatedProductListResponse.metaTagTitle || '';
        // this.isActive = relatedProductListResponse.isActive || 1;
        // this.sku = relatedProductListResponse.sku || '';
        // this.upc = relatedProductListResponse.upc || '';
        // this.quantity = relatedProductListResponse.quantity;
        this.productDetails = relatedProductListResponse.productDetails;
        this.productId = relatedProductListResponse.productId;
        this.productImage = relatedProductListResponse.productImage;
    }
}