import { Action } from '@ngrx/store';
import { type } from '../../shared/utility/utilityHelpers';
import { CartItem } from '../models/cart.models';

export interface IProductId {
    productId: number;
}

export interface IProductCountIncrementArg extends IProductId {
    increaseCountBy: number;
}

export interface IProductCountDecrementArg extends IProductId {
    decreaseCountBy: number;
}

export interface IAddToCartPayload {
    product: CartItem;
    itemCount?: number;
}

export const ActionTypes = {
    CART_ITEMS_LOAD: type('[Cart] Items Load'),
    CART_ITEMS_LOAD_PENDING: type('[Cart] Items Load Pending'),
    CART_ITEMS_LOAD_SUCCESS: type('[Cart] Items Load Success'),
    CART_ITEMS_LOAD_FAILURE: type('[Cart] Items Load Failure'),

    CART_ITEM_ADDITION: type('[Cart] Item Addition'),
    CART_ITEM_ADDITION_PENDING: type('[Cart] Item Addition Pending'),
    CART_ITEM_ADDITION_SUCCESS: type('[Cart] Item Addition Success'),
    CART_ITEM_ADDITION_FAILURE: type('[Cart] Item Addition Failure'),

    CART_ITEM_REMOVAL: type('[Cart] Item Removal'),
    CART_ITEM_REMOVAL_PENDING: type('[Cart] Item Removal Pending'),
    CART_ITEM_REMOVAL_SUCCESS: type('[Cart] Item Removal Success'),
    CART_ITEM_REMOVAL_FAILURE: type('[Cart] Item Removal Failure'),

    CART_ITEM_REMOVE_ALL: type('[Cart] Item Remove All'),
    CART_ITEM_REMOVE_ALL_PENDING: type('[Cart] Item Remove All Pending'),
    CART_ITEM_REMOVE_ALL_SUCCESS: type('[Cart] Item Remove All Success'),
    CART_ITEM_REMOVE_ALL_FAILURE: type('[Cart] Item Remove All Failure'),

    CART_ITEM_COUNT_INCREMENT: type('[Cart] Item Count Increment'),
    CART_ITEM_COUNT_INCREMENT_PENDING: type('[Cart] Item Count Increment Pending'),
    CART_ITEM_COUNT_INCREMENT_SUCCESS: type('[Cart] Item Count Increment Success'),
    CART_ITEM_COUNT_INCREMENT_FAILURE: type('[Cart] Item Count Increment Failure'),

    CART_ITEM_COUNT_DECREMENT: type('[Cart] Item Count Decrement'),
    CART_ITEM_COUNT_DECREMENT_PENDING: type('[Cart] Item Count Decrement Pending'),
    CART_ITEM_COUNT_DECREMENT_SUCCESS: type('[Cart] Item Count Decrement Success'),
    CART_ITEM_COUNT_DECREMENT_FAILURE: type('[Cart] Item Count Decrement Failure'),

    PRODUCT_BUY: type('[Details] By Product Now'),
    PRODUCT_BUY_PENDING: type('[Details] By Product Now Pending'),
    PRODUCT_BUY_SUCCESS: type('[Details] By Product Now Success'),
    PRODUCT_BUY_FAILURE: type('[Details] By Product Now Failure'),

    CART_CLEAR: type('[Cart] Clear Cart'),
}

export class CartItemsLoad implements Action {
    readonly type = ActionTypes.CART_ITEMS_LOAD;
}

export class CartItemsLoadPending implements Action {
    readonly type = ActionTypes.CART_ITEMS_LOAD_PENDING;
}

export class CartItemsLoadSuccess implements Action {
    readonly type = ActionTypes.CART_ITEMS_LOAD_SUCCESS;

    constructor(public payload: CartItem[]) {}
}

export class CartItemsLoadFailure implements Action {
    readonly type = ActionTypes.CART_ITEMS_LOAD_FAILURE;
}

export class CartItemAddition implements Action {
    readonly type = ActionTypes.CART_ITEM_ADDITION;

    constructor(public payload: IAddToCartPayload) {}
}

export class CartItemAdditionPending implements Action {
    readonly type = ActionTypes.CART_ITEM_ADDITION_PENDING;
}

export class CartItemAdditionSuccess implements Action {
    readonly type = ActionTypes.CART_ITEM_ADDITION_SUCCESS;

    constructor(public payload: CartItem) {}
}

export class CartItemAdditionFailure implements Action {
    readonly type = ActionTypes.CART_ITEM_ADDITION_FAILURE;
}

export class CartItemRemoval implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVAL;

    constructor(public payload: CartItem) {}
}

export class CartItemRemovalPending implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVAL_PENDING;
}

export class CartItemRemovalSuccess implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVAL_SUCCESS;

    constructor(public payload: CartItem) {}
}

export class CartItemRemovalFailure implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVAL_FAILURE;
}

export class CartItemRemoveAll implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVE_ALL;
}

export class CartItemRemoveAllPending implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVE_ALL_PENDING;
}

export class CartItemRemoveAllSuccess implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVE_ALL_SUCCESS;
}

export class CartItemRemoveAllFailure implements Action {
    readonly type = ActionTypes.CART_ITEM_REMOVE_ALL_FAILURE;
}

export class CartItemCountIncrement implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_INCREMENT;

    constructor(public payload: IProductCountIncrementArg) {}
}

export class CartItemCountIncrementPending implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_INCREMENT_PENDING;
}

export class CartItemCountIncrementSuccess implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_INCREMENT_SUCCESS;

    constructor(public payload: IProductCountIncrementArg) {}
}

export class CartItemCountIncrementFailure implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_INCREMENT_FAILURE;
}

export class CartItemCountDecrement implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_DECREMENT;

    constructor(public payload: IProductCountDecrementArg) {}
}

export class CartItemCountDecrementPending implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_DECREMENT_PENDING;
}

export class CartItemCountDecrementSuccess implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_DECREMENT_SUCCESS;

    constructor(public payload: IProductCountDecrementArg) {}
}

export class CartItemCountDecrementFailure implements Action {
    readonly type = ActionTypes.CART_ITEM_COUNT_DECREMENT_FAILURE;
}

export class ProductBuy implements Action {
    readonly type = ActionTypes.PRODUCT_BUY;

    constructor(public payload: IAddToCartPayload) {}
}

export class ProductBuyPending implements Action {
    readonly type = ActionTypes.PRODUCT_BUY_PENDING;
}

export class ProductBuySuccess implements Action {
    readonly type = ActionTypes.PRODUCT_BUY_SUCCESS;

    constructor(public payload: CartItem) {}
}

export class ProductBuyFailure implements Action {
    readonly type = ActionTypes.PRODUCT_BUY_FAILURE;
}

export class CartClear implements Action {
    readonly type = ActionTypes.CART_CLEAR;
}